// /src/About.js
import React from "react";
import "./About.css";

function About() {
    return (
        <div className="aboutPage">
            <div className="background">
                <div className="container">
                    <div className="content">
                        <h1>About</h1>
                        <p>
                            Thanks for checking out my Poetry Dispenser. I was inspired to make it while learning to use the OpenAI GPT API for another project. I read a <a href="https://medium.com/@sierraelman/the-future-of-poetry-26dabfc2f50a" rel="noreferrer" target="_blank">thoughtful but misguided Medium article</a> which opined that large-language models (LLMs like GPT) were unable to produce quality poetry that wasn't discernible from that of a human poet. The author purported to put some LLMs to a test, which I believe was unfair and stacked against AI models. I wanted to level the playground a bit, and the Poetry Dispenser was born. Admittedly, GPT responds with a fair bit of cheesy crap (human poets are not immune to this criticism either), but every once in a while there's a truly artful turn of phrase. Moreover, I submit that with my enhancements and some deliberate limitations, GPT would pass the author's tests.
                        </p>
                        <p>
                            GPT will not produce decent or even formally accurate poetry without input that focuses it on its required task. It tends to be overly assertive with rhymes even when told not to, and seems to have trouble with anything requiring randomness. To mitigate this, I wrote an instruction set with some guidance about how to craft more authentic examples of each type of poem. Douglas Adams' fictional Vogon poetry was the hardest by far, as there's not a lot of training data to draw on, and without highly specific instructions, it tended to produce something more akin to Lewis Carroll than a Vogon.
                        </p>
                        <p>
                            If you want to learn more about the project, or just want to say "hi", you can write me at <a href='mailto:poetry@kinetocreative.com'>poetry@kinetocreative.com</a> and if you're nice I will most likely respond. My name is Dwight. I won't use your email address for anything other than replying, because I am not a jerkweed.
                        </p>
                        {/* Add more content here as needed */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
