// src/GptForm.js
import React, { useState, useEffect } from "react";
import axios from "axios";

function GptForm({ assistantType, uniqueId }) {
  // Retrieve or initialize the prompt and responseText from sessionStorage
  const [prompt, setPrompt] = useState(() => {
    const savedPrompt = sessionStorage.getItem(`${assistantType}-prompt`);
    return savedPrompt || "";
  });
  const [responseText, setResponseText] = useState(() => {
    const savedResponse = sessionStorage.getItem(`${assistantType}-response`);
    return savedResponse || "";
  });

  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Update prompt and responseText based on the current assistantType
    const savedPrompt = sessionStorage.getItem(`${assistantType}-prompt`);
    const savedResponse = sessionStorage.getItem(`${assistantType}-response`);
    setPrompt(savedPrompt || "");
    setResponseText(savedResponse || "");
  }, [assistantType]); // Dependency array ensures this effect runs when assistantType changes

  const handleTextareaChange = (e) => {
    setPrompt(e.target.value);
    sessionStorage.setItem(`${assistantType}-prompt`, e.target.value); // Save prompt on change
  };

  // Modify the handleSubmit function to use assistantType-specific threadId

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check the honeypot field (for bot detection)
    const honeypotValue = e.target.email.value;
    if (honeypotValue) {
      console.log("Bot submission detected, aborting...");
      return; // Exit the function to prevent form submission
    }

    // Prevent empty prompt submission
    if (!prompt || prompt.trim() === "") {
      setResponseText(
          "What am I to do?\nWithout input I am just\nEchoing silence."
      );
      return; // Exit to prevent the API call
    }

    setIsLoading(true);

    const threadIdKey = `${assistantType}-threadId`;
    const threadId = sessionStorage.getItem(threadIdKey); // Retrieve threadId

    try {
      const response = await axios.post(`${apiUrl}/api/request`, {
        prompt,
        threadId,
        assistantType,
        uniqueId,
      });

      const newResponseText = response.data.responseText || "No text found";
      setResponseText(newResponseText);

      // Save the response and threadId to sessionStorage
      sessionStorage.setItem(`${assistantType}-response`, newResponseText);
      if (response.data.threadId) {
        sessionStorage.setItem(threadIdKey, response.data.threadId);
      }
    } catch (error) {
      console.error("Error fetching response:", error);
      setResponseText(
          "The syllable counter isn't available.\n" +
          error.message +
          "\nWe are trying to restart the abacus."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    const textToCopy = `Prompt: ${prompt}\n\n${responseText}`;

    // Attempt to use an intermediate textarea to ensure plain text copying
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Could not copy text: ", err);
    }
    document.body.removeChild(textArea);
  };
  const handleClear = () => {
    setPrompt("");
    setResponseText("");
    sessionStorage.removeItem(`${assistantType}-prompt`);
    sessionStorage.removeItem(`${assistantType}-response`);
  };
  const handleForget = () => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to forget these poems and start fresh? Other poetry types won't be affected."
    );

    // Only proceed if the user confirmed the action
    if (isConfirmed) {
      setPrompt("");
      setResponseText("");
      sessionStorage.removeItem(`${assistantType}-prompt`);
      sessionStorage.removeItem(`${assistantType}-response`);
      sessionStorage.removeItem(`${assistantType}-threadId`); // Ensure this is assistantType-specific

      // Optionally, clear all session storage if you really want everything forgotten
      // Be cautious, as this will remove everything stored in the session storage for your origin
      // sessionStorage.clear();
    }
  };

  return (
    <div className={assistantType.toLowerCase()}>
      <div className="background">
        <div className="container">
          <div className="panel">
            <form className="prompt" onSubmit={handleSubmit}>
              <textarea
                value={prompt}
                // onClick={handleTextareaClick}
                onChange={handleTextareaChange}
                className={prompt ? "has-content" : "empty"}
                minLength="1"
                maxLength="300"
                placeholder={"Say anything..."}
                rows="4"
              ></textarea>

              <input
                type="text"
                name="email"
                className="email"
                tabIndex="-1"
                autoComplete="off"
              />

              <button type="submit" disabled={isLoading}>
                {isLoading ? "Counting syllables..." : "Go"}
              </button>
            </form>
            <div
              className={`responseText ${
                responseText && !isLoading ? "fade-in" : ""
              }`}
            >
              {responseText}
            </div>
            <div className="icon-bar">
              <i
                className="fa-solid fa-copy"
                title="Copy"
                onClick={handleCopy}
              ></i>
              <i
                className="fa-solid fa-broom-wide"
                title="Clear Page"
                onClick={handleClear}
              ></i>
              <i
                className="fa-solid fa-trash-xmark"
                title="Forget All"
                onClick={handleForget}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GptForm;
